import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ROUTES, { LENDER_ROUTES } from 'legacy/_constants/routes';
import { Box, Group } from 'xen-ui';
import { useFeatureFlags } from 'xen/hooks';
import { useConfigurationContext } from 'xen/providers';
import { AppLayoutDivider } from '../../divider';
import { NavBarButton } from '../nav-bar-button';
import { NavBarLink } from '../nav-bar-link';
export const LenderNavBarBody = () => {
    const { bankingIntegration, funnelManagementPage, productEsigning } = useFeatureFlags();
    const { tenant } = useConfigurationContext();
    const { factoring } = tenant;
    return (_jsxs(_Fragment, { children: [factoring.enabled && (_jsx(NavBarLink, { active: location.pathname.startsWith(ROUTES.lender_home), icon: "Home", label: "Overview", to: "/factoring/lender-dashboard" })), _jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.prospects_overview) ||
                    location.pathname.startsWith(LENDER_ROUTES.underwrite_borrower_details), icon: "ListCheck", label: "Applications", to: funnelManagementPage ? '/prospects/lender-dashboard' : '/prospects' }), _jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.clients_overview), icon: "Users", label: "Clients", to: "/clients" }), factoring.enabled && (_jsx(NavBarButton, { icon: "ClipboardList", label: "Factoring", children: _jsxs(Group, { wrap: "nowrap", children: [_jsx(AppLayoutDivider, { orientation: "vertical" }), _jsxs(Box, { flex: '1 1 0', children: [_jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.schedules), label: "Invoice schedules", to: "/invoice-schedules" }), _jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.funding_requests), label: "Funding requests", to: "/funding-requests" }), _jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.invoice + '/'), label: "Invoices", to: "/invoices" }), _jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.debtor_details), label: "Debtors", to: "/debtors" }), _jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.debtor_payments_overview), label: "Payments", to: "/invoice-settlements" }), _jsx(NavBarLink, { label: "Unapplied cash", to: "/unapplied-cash" })] })] }) })), bankingIntegration && (_jsx(NavBarButton, { icon: "BuildingBank", label: "Accounts", children: _jsxs(Group, { wrap: "nowrap", children: [_jsx(AppLayoutDivider, { orientation: "vertical" }), _jsx(Box, { flex: '1 1 0', children: _jsx(NavBarLink, { label: "Linked accounts", to: "/linked-external-accounts" }) })] }) })), _jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.brokers), icon: "UserDollar", label: "Brokers", to: "/brokers" }), productEsigning && (_jsx(NavBarLink, { active: location.pathname.startsWith(LENDER_ROUTES.signing_documents), icon: "WritingSign", label: "Signing documents", to: "/signing-documents" }))] }));
};
