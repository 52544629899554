// Type guards ----------------------------------------------------------------
export const isRiskAlertOcrolusDocument = (riskAlert) => {
    return riskAlert.category === 'doc_validation';
};
export const isRiskAlertBankruptcyFiling = (riskAlert) => {
    return riskAlert.category === 'bankruptcy_filing';
};
export const isRiskAlertBusinessInfoVerification = (riskAlert) => {
    return riskAlert.category === 'business_info_verification';
};
export const isRiskAlertLienJudgement = (riskAlert) => {
    return riskAlert.category === 'lien_judgment';
};
export const isRiskAlertUccFiling = (riskAlert) => {
    return riskAlert.category === 'ucc_filing';
};
export const isRiskAlertPersonInfoVerification = (riskAlert) => {
    return riskAlert.category === 'person_info_verification';
};
