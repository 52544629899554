import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const defaultDeal = {
    archived: false,
    archivedAt: null,
    bankruptcyHistory: null,
    brokerCommissions: [],
    businessCity: null,
    businessCode: null,
    businessContacts: [],
    businessEin: null,
    businessEntity: null,
    businessName: null,
    businessOfficers: [],
    businessPhone: null,
    businessState: null,
    businessStreet: null,
    businessUnit: null,
    createdAt: '',
    debtors: [],
    documentSigners: [],
    dueDiligenceDocuments: [],
    fundingAmount: null,
    id: 0,
    isClient: false,
    lastAction: '',
    metaForm: {
        customFields: null,
        id: 1,
        name: '',
    },
    productType: 'factoring',
    salesPerson: null,
    salesPersonId: null,
    stageStatus: 'application_ready_to_start',
    state: 'pending',
    submissionDate: '',
    tellIsMore: null,
    tenantId: 0,
    updatedAt: '',
    viewedSinceLastAction: false,
};
const DealContext = createContext(defaultDeal);
export const DealProvider = ({ children, deal }) => {
    return _jsx(DealContext.Provider, { value: deal ?? defaultDeal, children: children });
};
export const useDealContext = () => {
    const context = useContext(DealContext);
    return context;
};
