import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Button, List, Modal, Stack, Text } from 'xen-ui';
import { useUpdateUser } from 'xen/api/use-user';
import { TextLink } from 'xen/components';
import { PRIVACY_NOTICE_URL, TERMS_AND_CONDITIONS_URL } from 'xen/constants';
import { useAcceptTerms, useAuthentication, useSearchParamState } from 'xen/hooks';
import { useUserContext } from 'xen/providers';
export const AcceptTermsModal = () => {
    const [acceptTerms, setAcceptTerms] = useSearchParamState('acceptTerms');
    const { hasAcceptedTerms, AcceptTermsCheckbox } = useAcceptTerms();
    const { isLoggedIn } = useAuthentication();
    const { id: userId, termsAcceptedAt } = useUserContext();
    const { mutateAsync: updateUser, isPending: isUpdateUserPending } = useUpdateUser({ urlParams: { userId } });
    useEffect(() => {
        // SHOW modal when user a) is logged in b) hasn't accepted terms
        if (isLoggedIn && !termsAcceptedAt) {
            setAcceptTerms('true');
        }
    }, [isLoggedIn, termsAcceptedAt]);
    const updateUserTermsAccepted = async () => {
        const user = await updateUser({ payload: { user: { termsAcceptedAt: new Date() } } });
        user.termsAcceptedAt && setAcceptTerms(undefined);
    };
    return (_jsx(Modal, { closeOnClickOutside: false, closeOnEscape: false, onClose: () => setAcceptTerms(undefined), opened: !!acceptTerms, padding: "xl", size: "lg", styles: { title: { fontSize: 'var(--mantine-font-size-xl)', fontWeight: 'bold' } }, title: "Accept terms & conditions", withCloseButton: false, zIndex: "calc(var(--mantine-z-index-modal) + 100)" // Ensure modal is on top of other modals
        , children: _jsxs(Stack, { gap: "lg", children: [_jsxs(Stack, { children: [_jsxs(Text, { children: ["We are updating our ", _jsx(TextLink, { to: PRIVACY_NOTICE_URL, children: "Privacy Notice" }), " and", ' ', _jsx(TextLink, { to: TERMS_AND_CONDITIONS_URL, children: "Terms & Conditions" }), ", effective November 11, 2024."] }), _jsx(Text, { size: "sm", children: "Here is a brief summary of the changes, but we encourage you to read them in full:" }), _jsxs(List, { pr: "lg", size: "sm", children: [_jsx(List.Item, { children: "Re-written and re-organized to make things easier to read and understand" }), _jsx(List.Item, { children: "Added more detail about the types of information we collect, where that information comes from, and how we use it" }), _jsx(List.Item, { children: "Made updates to reflect new laws and regulations which have come into effect" })] })] }), _jsx(Stack, { children: _jsx(Stack, { gap: "md", children: _jsxs(Stack, { gap: "md", children: [_jsx(AcceptTermsCheckbox, {}), _jsx(Button, { "data-autofocus": true, disabled: !hasAcceptedTerms, fullWidth: true, loading: isUpdateUserPending, onClick: updateUserTermsAccepted, children: "Continue" })] }) }) })] }) }));
};
