export const DEAL_STAGE_STATUSES = [
    'application_ready_to_start',
    'application_in_progress',
    'application_complete',
    'qualification_ready_to_start',
    'qualification_in_progress',
    'qualification_complete',
    'underwriting_ready_to_start',
    'underwriting_in_progress',
    'underwriting_complete',
    'contracting_ready_to_start',
    'contracting_in_progress',
    'contracting_complete',
];
// Original: src/legacy/_views/LenderViews/Audit/NewProspectAudit.jsx
// The only possible values for eventType are 'AUDIT' and 'USER_AUTH'
// https://github.com/Finvoice/finvoice_underwrite_api/blob/main/app/serializers/deal_event_serializer.rb#L15-L17
export var DealEventTypes;
(function (DealEventTypes) {
    DealEventTypes["AUDIT"] = "Audit";
    DealEventTypes["USER_AUTH"] = "Authentication";
})(DealEventTypes || (DealEventTypes = {}));
// Copied and modified from
// src/legacy/_views/LenderViews/Audit/NewProspectAudit.jsx
// src/legacy/_views/LenderViews/Audit/Audit.jsx
export var DealEventNames;
(function (DealEventNames) {
    DealEventNames["ARCHIVED"] = "Archived";
    DealEventNames["BUSINESS_CHANGE"] = "Business Changed";
    DealEventNames["BUSINESS_CONTACT_CREATE"] = "Business Contact Created";
    DealEventNames["BUSINESS_CONTACT_UPDATE"] = "Business Contact Changed";
    DealEventNames["BUSINESS_CREATE"] = "Business Created";
    DealEventNames["BUSINESS_OFFICER_ADDED"] = "New Business Officer Added";
    DealEventNames["BUSINESS_OFFICER_CHANGE"] = "Business Officer Changed";
    DealEventNames["BUSINESS_OFFICER_CREATE"] = "New Business Officer Created";
    DealEventNames["BUSINESS_OFFICER_UPDATE"] = "Business Officer Updated";
    DealEventNames["BUSINESS_OPENED"] = "Prospect Logged In";
    DealEventNames["BUSINESS_SUBMITTED"] = "Business Submission";
    DealEventNames["BUSINESS_UPDATE"] = "Business Updated";
    DealEventNames["CREATED"] = "Created";
    DealEventNames["CREDIT_REQUEST"] = "Credit Request";
    DealEventNames["DEBTOR_CREATE"] = "Debtor Created";
    DealEventNames["DEBTOR_UPDATE"] = "Debtor Changed";
    DealEventNames["FORGOT_PASSWORD"] = "Forgot Password";
    DealEventNames["FUNDED"] = "Funded";
    DealEventNames["LEAD_CREATED"] = "Lead Created";
    DealEventNames["LEADS"] = "Lead";
    DealEventNames["PENDING"] = "Pending";
    DealEventNames["QUICKBOOOKS_INTEGRATION_COMPLETED"] = "QuickBooks Integration Completed";
    DealEventNames["QUICKBOOOKS_INTEGRATION_STARTED"] = "QuickBooks Integration Started";
    DealEventNames["RESET_PASSWORD"] = "Reset Password";
    DealEventNames["SIGN_IN"] = "Sign In";
    DealEventNames["SIGN_OUT"] = "Signed Out";
    DealEventNames["SIGN_UP"] = "Sign Up";
    DealEventNames["SUBMISSIONS"] = "Submission";
    DealEventNames["USER_CHANGE"] = "Contact Changed";
    DealEventNames["XERO_INTEGRATION_COMPLETED"] = "Xero Integration Completed";
    DealEventNames["XERO_INTEGRATION_STARTED"] = "Xero Integration Started";
    DealEventNames["YODLEE_INTEGRATION_COMPLETED"] = "Yodlee Integration Completed";
    DealEventNames["YODLEE_INTEGRATION_STARTED"] = "Yodlee Integration Started";
})(DealEventNames || (DealEventNames = {}));
